.opaque {
  @apply rounded-lg !border-primary-100 !bg-info-90 !opacity-100;
}

.opaque::before {
  @apply !border-r-primary-100;
}

.opaque::after {
  @apply !border-r-info-90;
}
